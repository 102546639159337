<template>
  <div>
    <div v-if="data.style.type == 'singleton'" :style="style">
      <div v-for="(item, index) in imgList" :key="index">
        <el-image :src="item.image"
          :style="{marginTop: index > 0 ? `${data.style.imgbackGuge}px` : '', borderRadius: data.style.borderRadius? `${data.style.borderRadius}px`: '0px'}">
          <div slot="error" class="x-c" style="height: 200px">
            <i class="el-icon-picture-outline" style="font-size: 40px;color: #ababab;"></i>
          </div>
        </el-image>
      </div>
    </div>
    <div v-else-if="data.style.type == 'swiper'" :style="style">
      <el-carousel :height="carouselHeight" :interval="data.style.interval">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <el-image :src="item.image" @load="event => updateCarouselHeight(event, index)"
            :style="{borderRadius: data.style.borderRadius? `${data.style.borderRadius}px`: '0px',height:carouselHeight}"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="rollBox" v-else-if="data.style.type == 'roll'" :style="style">
      <div :style="{width: `calc(100% - ${data.style.pageBackGauge * 2}px)`}">
        <el-scrollbar>
          <el-image @load="updateCarouselHeight($event, index)" :id="'myImage'+index" :src="item.image"
            style="width: 100%;margin-right: 10px;" v-for="(item, index) in imgList" :key="index"
            :style="[ imgStyle,{marginLeft: index > 0 ? `${style.imgbackGuge}px` : ''}, {borderRadius: data.style.borderRadius? `${data.style.borderRadius}px`: '0px'},{height:carouselHeight}]">
            <div slot="error" class="x-c">
              <i class="el-icon-picture-outline" style="font-size: 40px;color: #ababab;"></i>
            </div>
          </el-image>
        </el-scrollbar>
      </div>
    </div>
    <div v-else-if="data.style.type == 'twoSwiper'" style="position: relative">
      <div class="qm-banner-bgImage uperUderImg">
        <div class="aaaa" v-for="(item,index) in underImgList" :style="{'opacity':item.opacity }" :key='index'>
          <el-image mode="widthFix" lazy-load :src="item.image" style="width: 100%;"
            :style="{height:carouselHeight}"></el-image>
        </div>
      </div>
      <el-carousel @change="ontransition" class="v-qm-banner" :height="carouselHeight" :interval="data.style.interval">
        <el-carousel-item class="swiper-item" v-for="(item, index) in imgList" :key="index">
          <el-image :src="item.bgImage" @load="event => updateCarouselHeight(event, index)"
            :style="{height:carouselHeight,width:'100%'}"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <Swiper  v-if="data.style.type == 'twoSwiper'" :swiperImgs="data.style.imgList" :height='data.style.height' :interval='data.style.interval'/> -->
  </div>
</template>

<script>
import { deepCopy } from '@/utils'
export default {
  name: 'guanggao',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      underImgList: [],
      currentIndex: 0,
      carouselHeight: 'auto'
    }
  },
  computed: {
    imgList() {
      let { imgList } = this.data.style
      // console.log('计算属性：', imgList)
      return imgList
    },
    // 父元素样式
    style() {
      let { style } = this.data
      let _style = {}
      if (this.data.style.type == 'singleton') {
        _style.margin = `0 ${style.pageBackGauge}px`

      } else if (this.data.style.type == 'swiper') {
        _style.marginLeft = `${style.pageBackGauge}px`
        _style.marginRight = `${style.pageBackGauge}px`
      } else if (this.data.style.type == 'roll') {
        _style.overflowX = 'scroll'
        _style.overflow = 'hidden';
        _style.whiteSpace = 'nowrap'
        _style.marginLeft = `${style.pageBackGauge}px`
        _style.marginRight = `${style.pageBackGauge}px`
        _style.width = '100%'
        _style.fontSize = '0'
        if (style.imgNum > 1) {
        }
      }
      // _style.marginLeft = `${style.pageBackGauge}px`
      return _style
    },
    // img item 样式
    imgStyle() {
      let { style } = this.data
      console.log('1阴影', style.type)
      let _style = {}
      if (style.type == 'singleton') {
        if (style.imgStyle == 'projection') {
          console.log('阴影')
          // _style.boxShadow = '0px -5px 0px 0px rgba(153,153,153,0.2), 0px 5px 0px 0px (153,153,153,0.2)'
          _style.border = ' 2px solid #eeeeee'
        }
      } else if (style.type == 'roll') {
        _style.width = `90%`
        // if(style.cornerStyle == 'round'){
        //   _style.borderRadius = `10px`
        // }
        if (style.imgStyle == 'projection') {
          // _style.boxShadow = '0px -5px 10px rgba(0,0,0,0.1), 0px 5px 10px rgba(0,0,0,0.1)'
          _style.border = ' 2px solid #eeeeee'
        }

        if (style.imgNum == 2) {
          _style.width = '55%'
        } else if (style.imgNum == 3) {
          _style.width = '33%'
        } else if (style.imgNum == 4) {
          _style.width = '25%'
        } else if (style.imgNum == 5) {
          _style.width = '20%'
        }
        if (style.imgNum > 1) {
          _style.height = '60px'
        }
      }
      return _style
    }
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
          if (this.data.style.type == 'twoSwiper') {
            this.underImgList = this.data?.style?.imgList?.map((v, i) => ({ index: i, bgImage: v.bgImage, opacity: i==0?1:0,image: v.image }))
          }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    animationfinish(data) {
      var that = this
      this.currentIndex = data.detail.current
      console.log(data.detail.current)
      that.underImgList.forEach((item, index) => {
        if (index == that.currentIndex) {
          item.opacity = 1
        } else {
          item.opacity = 0
        }
      })
    },
    ontransition(data) {
      this.currentIndex = data
      let underImgList = deepCopy(this.imgList)
      this.underImgList = underImgList.map((v, i) => ({ ...v, opacity: data == i ? 1 : 0 }))
    },
    updateCarouselHeight(event, index) {
      if (index === 0) {
        this.data.style.height = event.target.height
        this.carouselHeight = event.target.height + 'px';
        this.$emit('setTemp', this.data)
      }
    }
  }

}
</script>

<style scoped lang='scss'>
.v-qm-banner {
  background: transparent;
}
.qm-banner-bgImage {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
}

.uperUderImg {
  width: 100%;
  height: 100%;
}

.aaaa {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0) translateZ(0);
  transition: 1s;
}
.rollBox::-webkit-scrollbar {
  display: none;
}
</style>
