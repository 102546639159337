var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp x-x" }, [
    ![5].includes(_vm.data.sideNavStyle.sideNavType)
      ? _c(
          "div",
          {
            staticClass: "sideNav y-start",
            style: {
              backgroundColor:
                _vm.data.sideNavStyle.sideNavType === 2 ? "#ffffff" : "#f6f6f6",
            },
          },
          _vm._l(_vm.navList, function (item, index) {
            return _c("div", { key: index, staticStyle: { width: "100%" } }, [
              (
                item.type === 2
                  ? _vm.data.sideNavStyle.sideNavType !== 2 &&
                    _vm.data.sideNavStyle.sideNavType !== 3
                  : true
              )
                ? _c(
                    "div",
                    {
                      staticClass: "sideNavItem",
                      class: {
                        sideNavItemBgc: item.type === 0 || item.type === 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "activeNav",
                          class: {
                            activeNav1:
                              item.type === 0 &&
                              ![2, 3].includes(
                                _vm.data.sideNavStyle.sideNavType
                              ),
                            activeNav2:
                              item.type === 2 &&
                              ![2, 3].includes(
                                _vm.data.sideNavStyle.sideNavType
                              ),
                            yc: [2, 3].includes(
                              _vm.data.sideNavStyle.sideNavType
                            ),
                            activeNav3: _vm.data.sideNavStyle.sideNavType === 2,
                            activeNav4:
                              _vm.data.sideNavStyle.sideNavType === 2 &&
                              index === 0,
                          },
                        },
                        [
                          _vm.data.sideNavStyle.sideNavType != 0 &&
                          item.type != 2
                            ? _c("iconpark-icon", {
                                attrs: { name: "commodity", color: "#333333" },
                              })
                            : _vm._e(),
                          item.type === 2 &&
                          [0, 1, 4, 5].includes(
                            _vm.data.sideNavStyle.sideNavType
                          )
                            ? _c("iconpark-icon", {
                                attrs: { name: "dot", color: "#fe5a34" },
                              })
                            : _vm._e(),
                          _c("span", { staticClass: "fontS14c3" }, [
                            _vm._v(" " + _vm._s(item.name)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "goodsList" }, [
      [5, 4].includes(_vm.data.sideNavStyle.sideNavType)
        ? _c(
            "div",
            { staticClass: "x-fsa marB20" },
            _vm._l(_vm.classificationList, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "span",
                  {
                    staticClass: "fontS14",
                    style: { fontWeight: item.bold, color: item.color },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      ((_vm.data.sideNavStyle.sideNavType != 2 &&
        _vm.data.sideNavStyle.sideNavType != 3) ||
        !_vm.data.sideNavStyle.isWaterfallFlow) &&
      _vm.data.goodsStyle.isSortData
        ? _c(
            "div",
            { staticClass: "x-fsa marB20" },
            _vm._l(_vm.sortList, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "span",
                  {
                    staticClass: "fontS14",
                    style: { fontWeight: item.bold, color: item.color },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c("i", {
                  class: item.name === "价格" ? "el-icon-d-caret" : "",
                  style: { color: item.color },
                }),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "goodsInfo f-w" },
        [
          _vm.data.goodsStyle.isPoster
            ? _c("el-image", {
                staticClass: "marB10",
                staticStyle: { width: "100%", height: "120px" },
                attrs: {
                  src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-21/4221ca74aeb3465fb3bca330159fd79a.png",
                },
              })
            : _vm._e(),
          _vm._l(_vm.data.goodsStyle.goodsType === 2 ? 3 : 6, function (item) {
            return _c(
              "div",
              {
                key: item,
                staticClass: "x-x marB20 goodsItem",
                class: {
                  yStart: _vm.data.goodsStyle.goodsType != 0,
                  goodsTypeOne: _vm.data.goodsStyle.goodsType === 1,
                },
              },
              [
                _c("el-image", {
                  staticClass: "img marR10",
                  staticStyle: { height: "110px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-08/db3332b311ae4cf6b5dc4b4aede2b5e1.png",
                  },
                }),
                _c("div", { staticClass: "y-b info" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "fontS14 fontWeight7",
                        style: {
                          fontSize: _vm.data.goodsStyle.titleSize + "px",
                          color: _vm.data.goodsStyle.titleColor,
                        },
                      },
                      [_vm._v(" 商品名称 ")]
                    ),
                    _vm.data.goodsStyle.goodsType != 1
                      ? _c(
                          "div",
                          {
                            staticClass: "fontS12 annotateGrey omit marT5",
                            staticStyle: { width: "150px" },
                          },
                          [
                            _vm._v(
                              " 此处显示描述信息。建议使用该视图时，前往后台为商品添加详细描述信息…… "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "marT5" }, [
                      _vm.data.goodsStyle.isInventory
                        ? _c(
                            "span",
                            { staticClass: "fontS12 annotateGrey marR5" },
                            [_vm._v("库存：999")]
                          )
                        : _vm._e(),
                      _vm.data.goodsStyle.isSalesVolume
                        ? _c("span", { staticClass: "fontS12 annotateGrey" }, [
                            _vm._v("销量：666"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", [
                    _vm.data.goodsStyle.isPriceTag
                      ? _c(
                          "div",
                          {
                            staticClass: "marT5 marB5",
                            style: {
                              color: _vm.data.goodsStyle.priceTagColor,
                            },
                          },
                          [_vm._v(" 价格价签 ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "x-bc" },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "asterisk fontWeight7",
                              style: {
                                fontSize: _vm.data.goodsStyle.priceSize + "px",
                                color: _vm.data.goodsStyle.priceColor,
                              },
                            },
                            [_vm._v("￥66.")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "asterisk marR5",
                              style: {
                                fontSize:
                                  _vm.data.goodsStyle.priceSize - 4 + "px",
                                color: _vm.data.goodsStyle.priceColor,
                              },
                            },
                            [_vm._v("6/个")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "crossOut annotateGrey",
                              style: {
                                fontSize:
                                  _vm.data.goodsStyle.priceSize - 4 + "px",
                              },
                            },
                            [_vm._v("￥99.9")]
                          ),
                        ]),
                        _c("iconpark-icon", {
                          staticClass: "marR10",
                          attrs: {
                            name: "add-one",
                            color: "#fe5a34",
                            size: "18px",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }