<template>
    <div :style="{
        margin:
            data.marT +
            'px ' +
            data.marR +
            'px ' +
            data.marB +
            'px ' +
            data.marL +
            'px',
        borderRadius: data.borRadius + 'px'
    }">
        <div class="sousuo" :style="{ background: data.style.bgColor }">
            <div class="x-bc" :class="data.style.textPoint" :style="{
                height: `${data.style.frameworkHeigth}px`, background: data.style.framworkColor,
                borderRadius: `${data.style.frameworkStyle}px`, color: data.style.textColor
            }" style="padding: 0 15px">
                <div :class="data.style.textPoint" style="flex:1">
                    <iconpark-icon name="sousuo" size="16px"></iconpark-icon>
                    <span style="margin-left: 10px;" :style="{ color: data.style.textColor }">搜索商品</span>
                </div>
                <div v-if="data.style.isShowScan">
                 <el-image src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-09-09/43eaa8d8d1c54396b6be5622cb74ce43.png" style="height:15px;width:15px" />
             </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sousuo',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
}
</script>

<style scoped>
.sousuo {}
</style>
