var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        margin:
          _vm.data.marT +
          "px " +
          _vm.data.marR +
          "px " +
          _vm.data.marB +
          "px " +
          _vm.data.marL +
          "px",
        borderRadius: _vm.data.borRadius + "px",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "sousuo",
          style: { background: _vm.data.style.bgColor },
        },
        [
          _c(
            "div",
            {
              staticClass: "x-bc",
              class: _vm.data.style.textPoint,
              staticStyle: { padding: "0 15px" },
              style: {
                height: _vm.data.style.frameworkHeigth + "px",
                background: _vm.data.style.framworkColor,
                borderRadius: _vm.data.style.frameworkStyle + "px",
                color: _vm.data.style.textColor,
              },
            },
            [
              _c(
                "div",
                { class: _vm.data.style.textPoint, staticStyle: { flex: "1" } },
                [
                  _c("iconpark-icon", {
                    attrs: { name: "sousuo", size: "16px" },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "10px" },
                      style: { color: _vm.data.style.textColor },
                    },
                    [_vm._v("搜索商品")]
                  ),
                ],
                1
              ),
              _vm.data.style.isShowScan
                ? _c(
                    "div",
                    [
                      _c("el-image", {
                        staticStyle: { height: "15px", width: "15px" },
                        attrs: {
                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-09-09/43eaa8d8d1c54396b6be5622cb74ce43.png",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }