var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warp x-bc" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.isLayout === 0,
            expression: "data.isLayout === 0",
          },
        ],
        staticStyle: { "margin-left": "10px" },
      },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "x-f" },
          [
            _c("iconpark-icon", {
              staticStyle: { "margin-right": "5px" },
              attrs: { name: "shouhuodizhi1", size: "14px" },
            }),
            _c("span", { staticClass: "fontS12 annotateGrey" }, [
              _vm._v("距离您80m"),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.isLayout === 1,
            expression: "data.isLayout === 1",
          },
        ],
        staticStyle: { "margin-left": "10px" },
      },
      [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "x-f" },
          [
            _c("iconpark-icon", {
              staticStyle: { "margin-right": "5px" },
              attrs: { name: "shouhuodizhi1", size: "14px" },
            }),
            _c("span", { staticClass: "fontS12 annotateGrey" }, [
              _vm._v("店铺地址"),
            ]),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "switch" }, [
      _vm.data.receivingMethod1
        ? _c(
            "div",
            { class: { receivingMethod1Btn: _vm.data.receivingMethod1 } },
            [_vm._v(_vm._s(_vm.data.receivingMethod1))]
          )
        : _vm._e(),
      _vm.data.receivingMethod2
        ? _c(
            "div",
            { class: { receivingMethod1Btn: !_vm.data.receivingMethod1 } },
            [_vm._v(_vm._s(_vm.data.receivingMethod2))]
          )
        : _vm._e(),
      _vm.data.receivingMethod3
        ? _c(
            "div",
            {
              class: {
                receivingMethod1Btn:
                  !_vm.data.receivingMethod1 && !_vm.data.receivingMethod2,
              },
            },
            [_vm._v(_vm._s(_vm.data.receivingMethod3))]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c(
        "span",
        {
          staticClass: "fontS14 fontWeight7",
          staticStyle: { "margin-right": "5px" },
        },
        [_vm._v("店铺名称")]
      ),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c(
        "span",
        {
          staticClass: "fontS14 fontWeight7",
          staticStyle: { "margin-right": "5px" },
        },
        [_vm._v("店铺名称")]
      ),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }