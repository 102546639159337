var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warp x-bc",
      style: {
        background: _vm.data.isLayout === 1 ? _vm.data.style.bgColor : "",
        backgroundImage:
          _vm.data.isLayout === 2 ? "url(" + _vm.data.bgImg + ")" : "",
        backgroundRepeat: "no-repeat",
        margin:
          _vm.data.marT +
          "px " +
          _vm.data.marR +
          "px " +
          _vm.data.marB +
          "px " +
          _vm.data.marL +
          "px",
        borderRadius: _vm.data.borRadius + "px",
      },
    },
    [
      _c("div", { staticClass: "x-f", style: {} }, [
        _vm.data.logType !== 2
          ? _c(
              "div",
              {
                style: {
                  borderRadius: _vm.data.logType === 0 ? "30px" : "6px",
                  overflow: "hidden",
                },
              },
              [
                _c(
                  "el-image",
                  {
                    style: {
                      width: "38px",
                      height: "38px",
                      borderRadius: _vm.data.logType === 0 ? "30px" : "6px",
                    },
                    attrs: {
                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-05/531076396c104f8782c39f893f1055d5.png",
                      fit: "cover",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [
                        _c("div", [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _c("div", [_vm._v("上传图片")]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticStyle: { "margin-left": "10px" } }, [
          _c(
            "span",
            {
              staticClass: "fontS14 fontWeight7",
              style: {
                color: _vm.data.style.shopNameColor || "#333",
              },
            },
            [_vm._v("店铺名称")]
          ),
          _c("br"),
          _c(
            "span",
            {
              staticClass: "fontS12 annotateGrey",
              style: {
                color: _vm.data.style.angleMarkColor,
              },
            },
            [_vm._v(">100m ")]
          ),
          _c(
            "span",
            {
              style: {
                color: _vm.data.style.leftBtnColor,
              },
            },
            [_vm._v(" | ")]
          ),
          _c(
            "span",
            {
              style: {
                color: _vm.data.style.textColor,
              },
            },
            [
              _c("iconpark-icon", {
                attrs: { name: "shouhuodizhi1", size: "8px" },
              }),
              _vm._v(" 门店位置定位位置 "),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "switch" }, [
        _c(
          "div",
          {
            staticClass: "receivingMethod1Btn",
            style: { backgroundColor: _vm.data.style.btnBgColor || "#FF3C29" },
          },
          [
            _c("iconParkALi", {
              style: { color: _vm.data.style.iconColor || "#fff" },
              attrs: { name: _vm.data.seeMoreIcon, size: "12px" },
            }),
            _c(
              "span",
              { style: { color: _vm.data.style.iconText || "#fff" } },
              [_vm._v(" 切换门店 ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }