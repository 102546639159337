var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "wenben",
        style: {
          background: "" + _vm.data.style.bgColor,
          borderBottom: _vm.data.style.borderB ? ".5px solid #f5f5f5" : "none",
        },
      },
      [
        _c("div", { class: _vm.data.style.location }, [
          _c(
            "div",
            {
              style: {
                fontSize: _vm.data.style.titleFontSize + "px",
                fontWeight: "" + _vm.data.style.titleWeight,
                color: "" + _vm.data.style.titleColor,
              },
            },
            [_vm._v(" " + _vm._s(_vm.data.title) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "subhead",
              style: {
                fontSize: _vm.data.style.subheadFontSize + "px",
                fontWeight: "" + _vm.data.style.subheadWeight,
                color: "" + _vm.data.style.subheadColor,
              },
            },
            [_vm._v(" " + _vm._s(_vm.data.subhead) + " ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }