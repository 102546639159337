<template>
  <div>
    <div :style="{backgroundColor:data.contentStyle.bgColor||'#ffffff',padding:type==1?'12px 16px':''}">
      <div class="x-ac" :style="{boxShadow:type==1?'0 8px 24px rgba(0, 0, 0, .2)':'',borderRadius:type==1?'42px':'',height:type==1?'60px':'55px'}">
        <div v-for="(item, index) in data.contentStyle.navList" :key="index" class="y-c" :style="{width:`${100/data.contentStyle.navNumber}%`}">
          <el-image :src='index==0? item.checkIcon:item.icon' :style="{width:type==2&&item.type==2?'65px':'24px',
          height:type==2&&item.type==2?'65px':'24px',marginBottom:type==2&&item.type==2?'17px':'2px'
          }" v-if="data.contentStyle.type!=3"/>
          <div style="font-size:12px;"
            :style="{color:index==0?(data.contentStyle.checkTextColor||'#fe5933'):(data.contentStyle.textColor||'#333')}">
            {{ item.label||item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {}
  },
  computed:{
    type(){
      return this.data.contentStyle.type
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.warp {
  padding: 5px 0;
}
</style>
