<template>
    <!-- 中间展示商品样式组件 -->
    <div class="coupons">
        <div class="coupon-list padd10" v-if="data.style.curListStyleIndex == 0">
            <div class="" style="width: 100%;box-sizing: border-box">
                <CouponItem
                    style="width: 100%"
                    v-for="(item, index) in data.couponList.length ? data.couponList: data.temporaryCouponList" :key="index"
                    class="marB10"
                    :data="item"
                    :styleObj="data.style"
                ></CouponItem>
            </div>
        </div>
        <div class="padd10 x-w x-bc" v-if="data.style.curListStyleIndex == 1">
            <div class="x-w x-bc" style="width: 100%;box-sizing: border-box">
                <CouponItem
                    style="width: calc((100% - 20px) / 2) "
                    v-for="(item, index) in data.couponList.length ? data.couponList: data.temporaryCouponList" :key="index"
                    class="marB10"
                    :data="item"
                    :styleObj="data.style"
                ></CouponItem>
            </div>
        </div>
        <div class="padd10 x-w" v-if="data.style.curListStyleIndex == 2">
            <div class="x-w" style="width: 100%">
                <CouponItem
                    style="width: calc((100% - 30px) / 3);margin-right: 10px;"
                    v-for="(item, index) in data.couponList.length ? data.couponList: data.temporaryCouponList" :key="index"
                    :class="{'marB10': data.couponList.length ? data.couponList.length: data.temporaryCouponList.length > 3}"
                    :data="item"
                    :styleObj="data.style"
                ></CouponItem>
            </div>
        </div>
        <!--横向滚动-->
        <div class="swipe" v-if="data.style.curListStyleIndex == 3">
            <!-- <el-scrollbar> -->
                <div class="x-f padd10" style="width: 100%; overflow: hidden;">
                        <!-- :style="{width: `${calc(100% - (data.style.curCardStyleIndex!=4?(data.couponList.length||data.temporaryCouponList.length)*10:0)+'px')}/3`}" -->
                    <CouponItem
                        :style="[`min-width: calc(100% - 30px) / 3`]"
                        v-for="(item, index) in data.couponList.length ? data.couponList: data.temporaryCouponList" :key="index"
                        :class="{marR10:data.style.curCardStyleIndex!=4}"
                        :data="item"
                        :styleObj="data.style"
                    ></CouponItem>
                </div>
            <!-- </el-scrollbar> -->

        </div>
    </div>
</template>

<script>
import CouponItem
    from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/components/couponItem.vue'

export default {
    name: 'coupon',
    components: { CouponItem },
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    created() {
        console.log('===================', this.data.style.curCardStyleIndex)
    },
    data() {
        return {
            // cardList: [
            //     { id: 1, style: 'content2', text: '样式一' },
            //     { id: 2, style: 'content3', text: '样式二' },
            //     { id: 3, style: 'content4', text: '样式三' },
            //     { id: 4, style: 'content5', text: '样式四' },
            //     { id: 5, style: 'content6', text: '样式五' },
            //     { id: 6, style: 'content7', text: '样式六' },
            //     { id: 7, style: 'content8', text: '样式七' },
            //     { id: 8, style: 'yangshi9', text: '样式八' }
            // ]
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

//整体组件背景
.coupons {
    background-color: #f5f5f5;
    border: 1px solid #ffffff;
}

//.content1 {
//    /**
//        第一个radial-gradient ： 中间分割的圆点
//     */
//    -webkit-mask: radial-gradient(circle at 70%, red 1px, #0000 0) 70% 50% / 100% 5px,
//    radial-gradient(circle at 10px 10px, #0000 5px, red 0) -20px -10px / 73%,;
//    -webkit-mask-composite: destination-out;
//    mask-composite: subtract; /*Firefox*/
//}

//.content2 {
//    -webkit-mask: radial-gradient(circle at 10px, transparent 3px, red 0) -10px / 100% 10px;
//}

//.content3 {
//    -webkit-mask: radial-gradient(circle at 20px 20px, #0000 10px, red 0);
//    -webkit-mask-position: -20px -20px;
//}

//.content4 {
//    -webkit-mask: radial-gradient(circle at 100%, #0000 10px, red 0); /*4个角落各放一个圆*/
//    border-right: 20px solid;
//}

//一行一个
.list {
    width: 100%;
    height: 80px;

    .col {
        height: 80px;
    }

    .left {
        width: 70%;
        line-height: 1.8;
    }

    .right {
        width: 30%;
    }
}

// 一行两个
.list2 {
    width: calc((100% - 20px) / 2);
    height: 80px;
    margin-bottom: 10px;
}


//横向滑动
.swipe {
    //滚动条
    //::v-deep .el-scrollbar__wrap {
    //    overflow-x: auto;
    //}
    //
    //::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
    //    white-space: nowrap;
    //    display: flex;
    //}
    //
    //.goodsItem {
    //    flex-grow: 0;
    //    flex-shrink: 0;
    //    flex-basis: 30%;
    //}
}

</style>
