<template>
  <div class="warp x-x">
    <!-- :style="{
                color: data.contentStyle.titleColor
              }" -->

    <!-- 左侧导航 -->
    <div
      class="sideNav y-start"
      v-if="![5].includes(data.sideNavStyle.sideNavType)"
      :style="{
        backgroundColor:
          data.sideNavStyle.sideNavType === 2 ? '#ffffff' : '#f6f6f6'
      }"
    >
      <div v-for="(item, index) in navList" :key="index" style="width: 100%">
        <div
          v-if="
            item.type === 2
              ? data.sideNavStyle.sideNavType !== 2 &&
                data.sideNavStyle.sideNavType !== 3
              : true
          "
          class="sideNavItem"
          :class="{ sideNavItemBgc: item.type === 0 || item.type === 2 }"
        >
          <div
            class="activeNav"
            :class="{
              activeNav1:
                item.type === 0 && ![2, 3].includes(data.sideNavStyle.sideNavType),
              activeNav2:
                item.type === 2 && ![2, 3].includes(data.sideNavStyle.sideNavType),
              yc: [2, 3].includes(data.sideNavStyle.sideNavType),
              activeNav3: data.sideNavStyle.sideNavType === 2,
              activeNav4: data.sideNavStyle.sideNavType === 2 && index === 0
            }"
          >
            <iconpark-icon
              name="commodity"
              color="#333333"
              v-if="data.sideNavStyle.sideNavType != 0 && item.type != 2"
            />
            <iconpark-icon
              name="dot"
              color="#fe5a34"
              v-if="
                item.type === 2 && [0, 1, 4, 5].includes(data.sideNavStyle.sideNavType)
              "
            />
            <span class="fontS14c3"> {{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧商品 -->
    <div class="goodsList">
      <!-- 排序  v-if="
          data.sideNavStyle.isWaterfallFlow &&
          data.goodsStyle.goodsType != 2 &&
          data.goodsStyle.goodsType != 3
            ? false
            : true
        "-->
      <div
        class="x-fsa marB20"
        v-if="[5, 4].includes(data.sideNavStyle.sideNavType)"
      >
        <div v-for="(item, index) in classificationList" :key="index">
          <span
            class="fontS14"
            :style="{ fontWeight: item.bold, color: item.color }"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div
        class="x-fsa marB20"
        v-if="((data.sideNavStyle.sideNavType!=2&&data.sideNavStyle.sideNavType!=3)||!data.sideNavStyle.isWaterfallFlow)&&data.goodsStyle.isSortData"
      >
        <div v-for="(item, index) in sortList" :key="index">
          <span
            class="fontS14"
            :style="{ fontWeight: item.bold, color: item.color }"
            >{{ item.name }}</span
          >
          <i
            :class="item.name === '价格' ? 'el-icon-d-caret' : ''"
            :style="{ color: item.color }"
          />
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="goodsInfo f-w">
        <el-image
          v-if="data.goodsStyle.isPoster"
          class="marB10"
          style="width: 100%; height: 120px"
          src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-11-21/4221ca74aeb3465fb3bca330159fd79a.png"
        />
        <div
          v-for="item in data.goodsStyle.goodsType === 2 ? 3 : 6"
          :key="item"
          class="x-x marB20 goodsItem"
          :class="{
            yStart: data.goodsStyle.goodsType != 0,
            goodsTypeOne: data.goodsStyle.goodsType === 1
          }"
        >
          <!-- 图片 -->
          <el-image
            class="img marR10"
            style="height: 110px;"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-08/db3332b311ae4cf6b5dc4b4aede2b5e1.png"
          />
          <!-- 信息 -->
          <div class="y-b info">
            <!-- 上面 -->
            <div>
              <div
                class="fontS14 fontWeight7"
                :style="{
                  fontSize: data.goodsStyle.titleSize + 'px',
                  color: data.goodsStyle.titleColor
                }"
              >
                商品名称
              </div>
              <div
                class="fontS12 annotateGrey omit marT5"
                style="width: 150px"
                v-if="data.goodsStyle.goodsType != 1"
              >
                此处显示描述信息。建议使用该视图时，前往后台为商品添加详细描述信息……
              </div>
              <div class="marT5">
                <span
                  class="fontS12 annotateGrey marR5"
                  v-if="data.goodsStyle.isInventory"
                  >库存：999</span
                >
                <span
                  class="fontS12 annotateGrey"
                  v-if="data.goodsStyle.isSalesVolume"
                  >销量：666</span
                >
              </div>
            </div>
            <!-- 下面 -->
            <div>
              <!-- 价格价签 -->
              <div
                class="marT5 marB5"
                v-if="data.goodsStyle.isPriceTag"
                :style="{
                  color: data.goodsStyle.priceTagColor
                }"
              >
                价格价签
              </div>
              <div class="x-bc">
                <!-- 价格 -->
                <div>
                  <span
                    class="asterisk fontWeight7"
                    :style="{
                      fontSize: data.goodsStyle.priceSize + 'px',
                      color: data.goodsStyle.priceColor
                    }"
                    >￥66.</span
                  >
                  <span
                    class="asterisk marR5"
                    :style="{
                      fontSize: data.goodsStyle.priceSize - 4 + 'px',
                      color: data.goodsStyle.priceColor
                    }"
                    >6/个</span
                  >
                  <span
                    class="crossOut annotateGrey"
                    :style="{ fontSize: data.goodsStyle.priceSize - 4 + 'px' }"
                    >￥99.9</span
                  >
                </div>
                <!-- 加号 -->
                <iconpark-icon
                  class="marR10"
                  name="add-one"
                  color="#fe5a34"
                  size="18px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shangpinxinxi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {
      //排序功能
      sortList: [
        { name: '综合', bold: 700, color: '#333333' },
        { name: '销量', bold: 500, color: '#999999' },
        { name: '新品', bold: 500, color: '#999999' },
        { name: '价格', bold: 500, color: '#999999' }
      ],
      // 分类
      classificationList: [
        { name: '分类', bold: 700, color: '#333333' },
        { name: '分类', bold: 500, color: '#999999' },
        { name: '分类', bold: 500, color: '#999999' },
        { name: '分类', bold: 500, color: '#999999' }
      ],
      //侧边导航
      navList: [
        { name: '一级分类', type: 0 },
        { name: '二级分类', type: 2 },
        { name: '二级分类', type: 2 },
        { name: '一级分类', type: 1 },
        { name: '一级分类', type: 1 },
        { name: '一级分类', type: 1 },
        { name: '一级分类', type: 1 },
        { name: '一级分类', type: 1 }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//整体
.warp {
  background-color: #ffffff;
  //左侧导航
  .sideNav {
    width: 93px;
    //导航每项
    .sideNavItem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .activeNav {
        margin: 25px 0;
        text-align: center;
        margin-left: 1px;
        width: 100%;
        display: inline-block;
      }
      .activeNav1 {
        background-color: #ffffff;
        border-left: 2px solid #fe5a34;
      }
      .activeNav2 {
        background-color: #ffffff;
      }
      .yc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 71px;
        height: 66px;
        margin: 5px 0;
      }
      .activeNav3 {
        // border: 1px solid #cccccc;
        border-radius: 10px;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      }
      .activeNav4 {
        border: 1px solid #fe5a34;
        margin-top: 15px;
      }
    }
    .sideNavItemBgc {
      background-color: #ffffff;
    }
  }
  //右侧商品
  .goodsList {
    flex: 1;
    padding: 15px 0 0 10px;
    //商品信息整体
    .goodsInfo {
      //商品信息每一项
      .goodsItem {
        width: 100%;
        //图片
        .img {
          width: 100%;
        }
        //信息
        .info {
          width: 100%;
        }
      }
      /*竖直靠左、商品为双列、大图*/
      .yStart {
        flex-direction: column;
        align-items: flex-start;
      }
      //商品双列
      .goodsTypeOne {
        width: calc(50% - 10px);
        margin-right: 10px;
      }
    }
  }
}
</style>
