<template>
  <div>
    <div v-if="styleObj.curListStyleIndex > 0" class="item y-c padd10"
      :class="cardList[styleObj.curCardStyleIndex].style" :style="[
                {color: styleObj.textColor},
                {backgroundColor: styleObj.couponColor},
                {borderRadius: styleObj.curCardStyleIndex == 3 ? '5px' : ''},
                {width: styleObj.curCardStyleIndex==4?'126px':'100%'},
            ]">
      <div class="y-c introduce" style="width: 100%" :style="style2">
        <div v-if="styleObj.curCardStyleIndex==4" class="before" :style="{background:styleObj.couponColor}" />
        <div v-if="styleObj.curCardStyleIndex==4" class="after" :style="{background:styleObj.couponColor}" />
        <div class="omit1" :style="{color:styleObj.curCardStyleIndex==4?styleObj.couponColor:'#ffffff',
        fontSize:styleObj.curCardStyleIndex==4?'28px':'25px',
        fontWeight:styleObj.curCardStyleIndex==4?'600':'normal',
        }">
          <span v-if="data.couponCaseType == 0" style="font-size:20px">￥</span>
          <span>{{parValue(data)}}</span>
        </div>
        <div class="x-c received" :style="{color:styleObj.curCardStyleIndex==4?'#565656':'#ffffff',
        fontSize:'12px'
        }" :class="{omit : styleObj.curListStyleIndex > 0}" style="width: 100%;box-sizing: border-box;white-space: pre-wrap;">
          {{ couponDesc(data) }}
        </div>
      </div>
      <div v-if="styleObj.curCardStyleIndex==4" class="immediateClaim">
        立即领取
      </div>
    </div>
    <div v-else class="x-bc item marB10" :class="[
                cardList[styleObj.curCardStyleIndex].style,
                {'border': style.curCardStyleIndex == 4}]" :style="[
                {color: styleObj.textColor},
                {backgroundColor: `${styleObj.couponColor}`},
                {border: styleObj.curListStyleIndex == 3 ? `5px solid ${styleObj.couponColor}`: ``},
                {border: styleObj.curCardStyleIndex == 4 ? '5px' : ''},
                {borderRadius: styleObj.curCardStyleIndex == 3 ? '5px' : ''},
                {width: '100%'},
                {height: styleObj.curCardStyleIndex==4?'84px':''},
                {paddingBottom: styleObj.curCardStyleIndex==4?'5px':'10px'},
            ]">
      <div v-if="styleObj.curCardStyleIndex==4" class="x-f aRow" style="width: 80%">
        <div class="omit1 leftPrice x-fc" style="width:104px" :style="{color:styleObj.couponColor,fontSize:'28px', fontWeight:'600',
        }">
          <span v-if="data.couponCaseType == 0" style="font-size:20px">￥</span>
          <span>{{parValue(data)}}</span>
        </div>
        <div class="lines" :style="{background:styleObj.couponColor}" />
        <div class="before" :style="{background:styleObj.couponColor}" />
        <div class="after" :style="{background:styleObj.couponColor}" />
        <div class="y-start marL10" :style="{color:'#565656',
        fontSize:'12px'
        }">
          <div style="color: #212121;margin-bottom: 2px;font-size: 14px;line-height: 20px;font-weight: 600;">
            {{ data.couponCaseName }}</div>
          <div class="x-c" :class="{omit : styleObj.curListStyleIndex > 0}" style="width: 100%;box-sizing: border-box">
            {{ couponDesc(data) }}
          </div>
        </div>
        <div class="overhide">
          <div class="shadow" />
        </div>
      </div>
      <div v-else class="x-f introduce" style="width: 70%">
        <div v-if="styleObj.curCardStyleIndex==4" class="before" :style="{background:styleObj.couponColor}" />
        <div v-if="styleObj.curCardStyleIndex==4" class="after" :style="{background:styleObj.couponColor}" />
        <div class="omit1" style="width:80px" :style="{color:styleObj.curCardStyleIndex==4?styleObj.couponColor:'#ffffff',
        fontSize:styleObj.curCardStyleIndex==4?'28px':'25px',
        fontWeight:styleObj.curCardStyleIndex==4?'600':'normal',
        }">
          <span v-if="data.couponCaseType == 0" style="font-size:20px">￥</span>
          <span>{{parValue(data)}}</span>
        </div>
        <div class="y-start marL10" :style="{color:styleObj.curCardStyleIndex==4?'#565656':'#ffffff',
        fontSize:'12px'
        }">
          <div style="margin-bottom:5px">{{ data.couponCaseName }}</div>
          <div class="x-c" :class="{omit : styleObj.curListStyleIndex > 0}" style="width: 100%;box-sizing: border-box">
            {{ couponDesc(data) }}
          </div>
        </div>
      </div>
      <div style="width: 20%">
        <div class="x-fc" style="width:100%" v-if="styleObj.curCardStyleIndex==4">
          <div class="receive">
            立即领取
          </div>
        </div>
        <div class="x-c" v-else>
          立即领取
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'couponItem',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    styleObj: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cardList: [
        { id: 1, style: 'content2', text: '样式一' },
        { id: 2, style: 'content3', text: '样式二' },
        { id: 3, style: 'content4', text: '样式三' },
        { id: 4, style: 'content5', text: '样式四' },
        { id: 5, style: 'content6', text: '样式五' },
        { id: 6, style: 'content7', text: '样式六' },
        { id: 7, style: 'content8', text: '样式七' },
        { id: 8, style: 'content9', text: '样式八' }
      ]
    }
  },
  computed: {
    style() {
      let style = this.styleObj
      let _style = {}
      console.log('初始化颜色：', this.styleObj.textColor)
      console.log('初始化背景颜色：', this.styleObj.couponColor)
      // _style.color = `${style.textColor}`
      // _style.background = `${style.couponColor}`
      // if (style.curListStyleIndex == 3) {
      // _style.borderWidth = `5px solid ${style.couponColor}`
      // }
      // if (style.curCardStyleIndex == 4) {
      //     _style.border = `3px solid ${style.couponColor}`
      //     _style.background = `#fdf1ed`
      //     _style.color = `${style.couponColor}`
      //     _style.padding = `5px`
      // }

      if (style.curCardStyleIndex == 4) {
        _style.border = `3px solid ${style.couponColor}`
        _style.background = `#fdf1ed`
        _style.color = `${style.couponColor}`
        _style.padding = `5px`
      }
      if (style.curCardStyleIndex == 5) {
        _style.borderRadius = '5px'
      }


      return _style

    },
    style2() {
      let style = this.styleObj
      let _style = {}
      if (style.curCardStyleIndex == 4) {
        _style.border = `1px solid ${style.couponColor}`
      }

      return _style
    },
    couponDesc() {
      return function (val) {
        //sillType  0无门槛  1有的
        return val.sillType == 0 ? `无门槛,${val.goodsRangeType == 0 ? '全部商品' : '指定商品'}` : `满${val.sillMoney || 0}元可用，${val.goodsRangeType == 0 ? '全部商品' : '指定商品'}`;
      }
    },
    parValue() {
      return function (val) {
        //couponCaseType  0代金券  1折扣券  2抵扣券
        return val.couponCaseType == 0 ? `${val.parValue || 0}` : val.couponCaseType == 1 ? `${val.parValue / 10 || 0}折` : val.couponCaseType == 2 ? `兑` : '';
      }
    },
  },
  created() {
    console.log('========颜色===========', this.styleObj.couponColor)
  }
}
</script>

<style lang="scss" scoped>
.item {
  height: 80px;
  padding: 5px;
}

.content1 {
  /**
        第一个radial-gradient ： 中间分割的圆点
     */
  -webkit-mask: radial-gradient(circle at 70%, red 1px, #0000 0) 70% 50% / 100%
      5px,
    radial-gradient(circle at 10px 10px, #0000 5px, red 0) -20px -10px / 73%;
  -webkit-mask-composite: destination-out;
  mask-composite: subtract; /*Firefox*/
}

.content2 {
  padding: 10px 15px 10px 20px;
  -webkit-mask: radial-gradient(circle at 10px, transparent 3px, red 0) -10px / 100%
    10px;
}

.content3 {
  padding: 10px 15px 10px 20px;
  -webkit-mask: radial-gradient(circle at 20px 20px, #0000 10px, red 0);
  -webkit-mask-position: -20px -20px;
}

.content4 {
  border-width: 5px 20px 5px 5px;
  -webkit-mask: radial-gradient(circle at 100%, #0000 10px, red 0);
  border-radius: 5px;
}

.content6 {
  border-radius: 12px;
  height: 97px;
  width: 126px;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 5px 5px 0 5px;
  .introduce {
    position: relative;
    background-color: #ffffff;
    width: 116px;
    height: 64px;
    z-index: 1;
    border-radius: 8px;
    .before {
      position: absolute;
      border-radius: 100%;
      top: 25px;
      width: 12px;
      height: 12px;
      left: -6px;
    }
    .after {
      position: absolute;
      border-radius: 100%;
      left: auto;
      right: -6px;
      top: 25px;
      width: 12px;
      height: 12px;
    }
  }
  .aRow {
    position: relative;
    background-color: #ffffff;
    height: 68px;
    margin-left: 3px;
    z-index: 1;
    border-radius: 8px;
    .leftPrice {
      width: 104px;
      color: #ff3c29;
      font-weight: 600;
      text-align: center;
    }
    .lines {
      position: relative;
      height: 42px;
      width: 0;
      border: 1px dashed #ccc;
      background-color: transparent !important;
    }
    .before {
      position: absolute;
      border-radius: 100%;
      top: -7px;
      left: 99px;
      width: 12px;
      height: 12px;
    }
    .after {
      position: absolute;
      border-radius: 100%;
      bottom: -7px;
      left: 99px;
      width: 12px;
      height: 12px;
    }
    .overhide {
      position: absolute;
      right: -4px;
      width: 4px;
      height: 58px;
      overflow: hidden;
      z-index: 1;
      background: transparent;
      .shadow {
        position: absolute;
        right: 0px;
        width: 7px;
        height: 58px;
        opacity: 0.3;
        background: #fff;
        border-radius: 0 50px 50px 0;
      }
    }
  }
  .immediateClaim {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 5px;
    padding: 0;
    max-width: 100px;
  }
  .receive {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 38px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  }
}

.border {
  border: 3px solid;
}
</style>
