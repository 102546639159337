var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        style: {
          backgroundColor: _vm.data.contentStyle.bgColor || "#ffffff",
          padding: _vm.type == 1 ? "12px 16px" : "",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "x-ac",
            style: {
              boxShadow: _vm.type == 1 ? "0 8px 24px rgba(0, 0, 0, .2)" : "",
              borderRadius: _vm.type == 1 ? "42px" : "",
              height: _vm.type == 1 ? "60px" : "55px",
            },
          },
          _vm._l(_vm.data.contentStyle.navList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "y-c",
                style: { width: 100 / _vm.data.contentStyle.navNumber + "%" },
              },
              [
                _vm.data.contentStyle.type != 3
                  ? _c("el-image", {
                      style: {
                        width:
                          _vm.type == 2 && item.type == 2 ? "65px" : "24px",
                        height:
                          _vm.type == 2 && item.type == 2 ? "65px" : "24px",
                        marginBottom:
                          _vm.type == 2 && item.type == 2 ? "17px" : "2px",
                      },
                      attrs: { src: index == 0 ? item.checkIcon : item.icon },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "12px" },
                    style: {
                      color:
                        index == 0
                          ? _vm.data.contentStyle.checkTextColor || "#fe5933"
                          : _vm.data.contentStyle.textColor || "#333",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.label || item.name))]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }