<template>
  <div class="warp x-bc">
    <!-- 左边 -->
    <div v-show="data.isLayout === 0" style="margin-left:10px">
      <div class="x-f">
          <span class="fontS14 fontWeight7" style="margin-right:5px">店铺名称</span>
          <i class="el-icon-arrow-right"/>
        </div>
      <div class="x-f">
        <iconpark-icon name="shouhuodizhi1" size="14px" style="margin-right:5px"/>
        <span class="fontS12 annotateGrey">距离您80m</span>
        </div>
    </div>
    <!-- 左边 -->
    <div  v-show="data.isLayout === 1" style="margin-left:10px">
     <div class="x-f">
          <span class="fontS14 fontWeight7" style="margin-right:5px">店铺名称</span>
          <i class="el-icon-arrow-right"/>
      </div>
     <div class="x-f">
        <iconpark-icon name="shouhuodizhi1" size="14px"  style="margin-right:5px"/>
        <span class="fontS12 annotateGrey">店铺地址</span>
      </div>
    </div>
    <!-- 右边 -->
    <div class="switch">
      <div :class="{receivingMethod1Btn:data.receivingMethod1}" v-if="data.receivingMethod1">{{ data.receivingMethod1 }}</div>
      <div :class="{receivingMethod1Btn:!data.receivingMethod1}" v-if="data.receivingMethod2">{{ data.receivingMethod2 }}</div>
      <div :class="{receivingMethod1Btn:!data.receivingMethod1&&!data.receivingMethod2}" v-if="data.receivingMethod3">{{ data.receivingMethod3 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fuwufangshi',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
//大盒
.warp {
  height: 57px;
  background-color: #fff;
  //定位
  .el-icon-location-outline {
    font-size: 23px;
  }
  //开关
  .switch {
    display: flex;
    font-size: 12px;
    color: #333333;
    border-radius: 42px;
    background-color: #eeeeee;
    margin-right: 10px;
    div {
      padding: 5px 13px 6px;
      margin: -1px;
    }
    //收货方式第一种
    .receivingMethod1Btn {
      color: #fff;
      background-color: #fe5f3a;
      margin-right: -5px;
      border-radius: 42px;
    }
  }
}
</style>
